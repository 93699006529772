import * as API from '../../index';
export default {
    //护理周期
    skinUsePeriod:params =>{
        return API.POST('api/skinUsePeriod/all',params)
    },
    //创建护理周期
    create:params =>{
        return API.POST('api/skinUsePeriod/create',params)
    },
    // 编辑护理周期
    update:params =>{
        return API.POST('api/skinUsePeriod/update',params)
    },

}